import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
    <NewsArticle>
      <h1 className='page-title'>Changes to our mortgage products</h1>
     <p>With effect from Friday 1 July 2022 we will be making the following changes to our range:</p>
     <p><strong>Summary of changes</strong></p>
     <p>With effect from Friday 1 July 2022 we are changing our Residential Standard Variable Rate from 4.04% to 4.29%.</p>
     <p>Any customers who are currently on our Residential Standard Variable Rate will be notified of changes to their payments in writing.</p>
     <p>There are no changes to our Buy to Let Standard Variable Rate or any of our other rates at this time.</p>
     <p>An updated mortgage rate sheet will be published on Friday 1 July 2022 to reflect these changes.</p>
     <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
    </NewsArticle>
)

export default Article